/** @jsx jsx */
import React from 'react'
import { css, jsx, ThemeProvider } from '@emotion/react'
import Layout from '../components/Layout'
import { Column } from '@swp/components/lib/Column'
import { ColumnWrap } from '@swp/components/lib/Column'
import { ColumnWrapper } from '@swp/components/lib/Column'
import { Image } from '@swp/components/lib/Image'
import { Text } from '@swp/components/lib/Text'
import { Title } from '@swp/components/lib/Title'
import { Form } from '@swp/components/lib/Form'

const theme = {
  "id": 58,
  "colors": {
    "front1": "rgba(20,20,20,1)",
    "front2": "rgba(255,255,255,1)",
    "front3": "rgba(17,17,17,1)",
    "front4": "rgba(178,178,178,1)",
    "back1": "rgba(255,255,255,1)",
    "back2": "rgba(0,0,0,1)",
    "back3": "rgba(236,236,236,1)",
    "back4": "rgba(178,178,178,1)"
  },
  "variables": {
    "color-variable-1": "rgba(0,0,0,1)",
    "color-variable-2": "rgba(0,0,0,1)",
    "color-variable-btn-1": "rgba(255, 255, 255, 1)",
    "color-variable-btn-2": "rgba(0, 0, 0, 1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function Page(props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout >
        <Column className="--l8w --center mb--0 pb--0 pt--20" name={"h9b2hjtoc28"} style={{"paddingTop":null,"marginBottom":null,"paddingBottom":null}}>
          
          <ColumnWrap className="column__flex --center el--3 pb--0 pt--20" style={{"paddingTop":null,"paddingBottom":null}} columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pb--0 pt--0" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/3727/ba6b6b3085934ef6a79cc4680806ec1d.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingTop":null,"paddingBottom":null}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--0 pt--0" name={"at7rbys93o7"} style={{"marginTop":0,"paddingTop":null,"marginBottom":0,"paddingBottom":null}} layout={"l3"} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/3727/05f7b5867c594ecaa2c10026fe224f5c_s=2000x_.jpg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/3727/05f7b5867c594ecaa2c10026fe224f5c_s=1400x_.jpg);
          }
        `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--20 w--500 ls--02 lh--14 pt--16" style={{"paddingTop":null,"paddingBottom":38.42498779296875}} content={"<span style=\"font-weight: bold;\"><br><br>•Nákup, prodej a pronájem nemovitostí\n<br>&nbsp;•Administrativní a konzultační služby<br><br>DANUVIOS s.r.o.</span><br>Ke dvoru 858/27<br>Vokovice, Praha 6<br>160 00<br>IČ: 08127191<br>DIČ: CZ08127191<br><br><br><br>E-mail: info@danuvios.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"abbvig2vw2q"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Kontaktujte nás"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":600}} content={"Máte dotaz? Potřebujete poradit? Nebo jen nám chcete něco sdělit? Napište nám pomocí kontaktního formuláře - my se Vám zpětně ozveme."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Form action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l3"}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeProvider>
  )
}