import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export default function SEO(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)
  const title = props.title || siteMetadata.title
  const description = props.description || siteMetadata.description
  const favicon = siteMetadata.favicon

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {favicon ? <link rel="shortcut icon" href={favicon} /> : null}
    </Helmet>
  )
}

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        favicon
      }
    }
  }
`
